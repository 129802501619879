import React from "react";
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

import Nav from "../../components/alternative/Nav-alt.de"
import Layout from "../../components/alternative/layout-alt.de";
import Seo from "../../components/seo";

export const query = graphql`query ThankYouDeSupportPageQuery {
  office: file(relativePath: {eq: "rentware-office-adlershof.jpg"}) {
    childImageSharp {
      gatsbyImageData(layout: FULL_WIDTH)
    }
  }
}
`

const ThankYou = ({ data }) => (
  <Layout>
    <Seo
      seo={{
        metaTitle: "Thank you",
        metaDescription: "Danke für Ihre Nachricht",
      }}
    />
    <Nav />
      <main>
          <div className="mb-10 hero__wrapper hero__wrapper--content-left">
              <div className={"hero mt-8 md:mt-0 mx-auto max-w-screen-3xl"}>
                  <div className={"hero__text p-4 md:p-10 lg:p-16 xl:p-20 2xl:pl-48 md:absolute md:z-10 md:bg-secondary-bg md:w-3/5 xl:w-2/3"}>
                      <h1>Vielen Dank für Ihre Nachricht.</h1>
                      <p>Wir bearbeiten ihr Anliegen so schnell wie möglich und werden uns mit Ihnen in Kontakt setzen.</p>
                  </div>
                  <div className="w-full max-w-screen-md ml-auto hero__image">
                      <GatsbyImage
                        image={data.office.childImageSharp.gatsbyImageData}
                        className="min-h-56"
                        alt="TODO" />
                  </div>
              </div>
          </div>
      </main>
  </Layout>
);

export default ThankYou;
